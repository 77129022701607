import dynamic from 'next/dynamic';
import Layout from "@/components/Layout";
// import getJobCount from "@/features/home/apis/getJobCount";
// import Home from "@/features/home/components";
// import DEVICE_REGEXP from "@/constant";
import DEVICE_REGEXP_NEW from "@/constant/deviceDetect";
import ssgAuth from "@/auth/ssgAuth";

const Home = dynamic(() => import('@/features/home/components'), {ssr: true});
const HomePage = Layout((props) => <Home {...props} />);

export const getServerSideProps = async (ctx) => {
  const { status, path } = await ssgAuth(ctx);

  if (status) {
    return {
      redirect: {
        destination: path,
        permanent: false,
      },
    };
  }

  try {
    const userAgent = ctx?.req?.headers["user-agent"];
    let device = DEVICE_REGEXP_NEW(ctx?.req);
    const isDesktopViewer = ctx?.req?.headers['cloudfront-is-desktop-viewer'];
    
    if(isDesktopViewer){
      if (isDesktopViewer === 'true') {
        device = "desktop";
      }
    }
    return {
      props: {
        device: device,
        userAgent: userAgent,
      },
    };
  } catch (err) {
    console.error("error : : ", err);
    return {
      notFound: true,
    };
  }
};

export default HomePage;
